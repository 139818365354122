import { useState, useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import L from "leaflet";
import icon from "./iconSearch"; // Ваша иконка маркера
import { lanGs } from "../../../utils/langData";
import '../Style/LeafletControlGeocoder.css'
let layerGroup = L.layerGroup(); // Для группировки маркеров

const LeafletControlGeocoder = ({ lang }) => {
    const map = useMap();
    const inputRef = useRef(null); // Создаем реф для поля ввода
    const searchButtonRef = useRef(null); // Создаем реф для кнопки поиска
    const [printInfo, setPrintInfo] = useState(""); // Для отображения информации о месте
    const [controlAdded, setControlAdded] = useState(false); // Флаг для проверки, что контрол уже добавлен
    let city = "Алматы ";

    // Функция для выполнения поиска через Яндекс
    const geocodeYandex = (query) => {
        const apiKey = process.env.REACT_APP_YANDEX_API_KEY;
        const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=${city + query}&format=json&ll=76.9286,43.2567&spn=2.0,2.0&results=10&strictBounds=true`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                const geoObjects = data.response.GeoObjectCollection.featureMember;

                if (geoObjects.length === 0) {
                    return;
                }

                // Проверяем и выводим результат
                const options = geoObjects.map((item) => ({
                    name: item.GeoObject.name,
                    description: item.GeoObject.description,
                    coordinates: item.GeoObject.Point.pos.split(" "), // Разделяем координаты
                }));

                const { coordinates, name } = options[0]; // Берем первый результат
                const [longitude, latitude] = coordinates;

                // Удаляем все старые маркеры
                layerGroup.clearLayers();

                // Устанавливаем новый маркер
                const latlng = new L.LatLng(latitude, longitude);
                L.marker(latlng, { icon })
                    .addTo(layerGroup) // Добавляем в группу маркеров
                    .bindPopup(name)
                    .openPopup();

                layerGroup.addTo(map); // Добавляем группу на карту
                map.panTo(latlng); // Панируем карту
                setPrintInfo(name); // Обновляем информацию
            })
            .catch((error) => {
                console.error("Ошибка геокодирования:", error);
            });
    };

    // Функция для выполнения поиска
    const handleSearch = () => {
        const searchQuery = inputRef.current?.value; // Получаем значение напрямую из рефа
        if (searchQuery?.trim().length > 0) {
            geocodeYandex(searchQuery); // Выполняем поиск по Яндексу
        } else {
            console.log("Поле поиска пустое");
        }
    };

    useEffect(() => {
        let text = lanGs["Введите адрес"][lang]; // Получаем текст на выбранном языке

        if (map && !controlAdded) {
            layerGroup.clearLayers(); // Очищаем маркеры перед каждым новым поиском

            const control = L.Control.extend({
                onAdd: function () {
                    const container = L.DomUtil.create(
                        "div",
                        "leaflet-bar leaflet-control leaflet-control-custom"
                    );

                    // Создаем поле ввода и привязываем к нему реф
                    const input = L.DomUtil.create("input", "search-input", container);
                    input.type = "text";
                    input.placeholder = text; // Устанавливаем placeholder на текущем языке
                    inputRef.current = input; // Привязываем реф

                    // Создаем кнопку поиска
                    const searchButton = L.DomUtil.create("button", "search-button", container);
                    searchButton.innerHTML = lanGs["Поиск"][lang]; // Устанавливаем текст кнопки на текущем языке
                    searchButtonRef.current = searchButton; // Привязываем реф

                    // Обрабатываем клик по кнопке поиска
                    searchButton.addEventListener("click", (e) => {
                        e.preventDefault();
                        handleSearch(); // Выполняем поиск
                    });

                    // Обрабатываем нажатие клавиши Enter
                    input.addEventListener("keydown", (e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            handleSearch(); // Выполняем поиск
                        }
                    });

                    return container;
                },
            });

            map.addControl(new control({ position: "topright" })); // Добавляем кастомный контрол
            setControlAdded(true); // Флаг, что контрол добавлен
        }
    }, [map, controlAdded]); // Добавляем зависимости только для создания контрола

    useEffect(() => {
        // Здесь мы отслеживаем изменения языка и обновляем placeholder и текст кнопки
        if (inputRef.current) {
            inputRef.current.placeholder = lanGs["Введите адрес"][lang]; // Обновляем placeholder
        }
        if (searchButtonRef.current) {
            searchButtonRef.current.innerHTML = lanGs["Поиск"][lang]; // Обновляем текст кнопки
        }
    }, [lang]); // Обновляем при изменении языка

    return <div className="printInfo">{printInfo}</div>; // Выводим выбранный адрес
};

export default LeafletControlGeocoder;
