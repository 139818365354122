import React, { useEffect, useState } from "react";
import Main from "../common/Map/Main";
import "./CityInfrastructure.css";

import axios from "axios";
import HeaderBlock from "./HeaderBlock";

const CityInfrastructure = () => {
  const [lang, setLang] = useState("nameKz");
  const [data, setData] = useState(null);
  const [isLoad, setIsLoad] = useState(false);


  const [toMark, setToMark] = useState(null);

  useEffect(() => {
    setIsLoad(true);
    setData(null);

    axios({
      method: "get",
      url: "/sc-gis/api/v1/ref/maslikhat-shapes",
    })
      .then((res) => {

        setData(null);
        setData(res.data);
          setIsLoad(false)
      })
      .catch(function (error) {

      });
  }, []);




  return (
    <div className={"CityInfrastructure_wrap"}>
      <HeaderBlock lang={lang} setLang={setLang}/>

      <Main
        lang={lang}
        data={data}
        setData={setData}
        toMark={toMark}
        setToMark={setToMark}
        isLoad={isLoad}
        setIsLoad={setIsLoad}

      />
    </div>
  );
};

export default CityInfrastructure;
