export const lanGs = {
  "Оставить отзыв": {
    nameRu: "Оставить отзыв",
    nameKz: "Пікір қалдыру",
  },
  "Инфраструктура города": {
    nameRu: "Инфраструктура города",
    nameKz: "Қаланың инфрақұрылымы",
  },

  "Центр развития города Алматы": {
    nameRu: "Центр развития города Алматы",
    nameKz: "Алматы қаласын дамыту орталығы",
  },
  "Акимат города Алматы": {
    nameRu: "Акимат  города Алматы",
    nameKz: "Алматы қаласының әкімдігі",
  },
  "Управление Цифровизации города Алматы": {
    nameRu: "Управление   Цифровизации города Алматы",
    nameKz: "Алматы қаласы Цифрландыру басқармасы",
  },
  'Вход': {
    nameRu: "Вход",
    nameKz: "Кіру",
  },
  'Статус': {
    nameRu: "Статус",
    nameKz: "Мәртебесі",
  },
  "Главная страница": {
    nameRu: "Главная страница",
    nameKz: "Басты бет",
  },
  "Ситуационный центр": {
    nameRu: "Ситуационный центр",
    nameKz: "Ахуалдық орталық",
  },
  "Не указан": {
    nameRu: "Не указан",
    nameKz: "Көрсетілмеген",
  },
  "Выбор района": {
    nameRu: "Выбор района",
    nameKz: "Аудан таңдау",
  },
  'Поиск по названию': {
    nameRu: "Поиск по названию",
    nameKz: "Аты бойынша іздеу",
  },
  'Район': {
    nameRu: "Район",
    nameKz: "Аудан",
  },
  'Адрес объекта': {
    nameRu: "Адрес объекта",
    nameKz: "Объектінің мекенжайы",
  },
  'Нет метки': {
    nameRu: "Нет метки",
    nameKz: "Белгі жоқ",
  },
  'Приблизить': {
    nameRu: "Приблизить",
    nameKz: "Жуықтау",
  },
  'Расположение': {
    nameRu: "Расположение",
    nameKz: "Орналасқан жері",
  },
  'Адрес': {
    nameRu: "Адрес",
    nameKz: "Мекен-жайы",
  },
  'Описание': {
    nameRu: "Описание",
    nameKz: "Сипаттама",
  },
  'найдено': {
    nameRu: "найдено",
    nameKz: "табылды",
  },
  'Показать': {
    nameRu: "Показать",
    nameKz: "Көрсету",
  },
  'Очистить': {
    nameRu: "Очистить",
    nameKz: "Тазалау",
  },
  'Сбросить фильтры': {
    nameRu: "Сбросить фильтры",
    nameKz: "Сүзгілерді қалпына келтіріңіз",
  },
  'Применить фильтры': {
    nameRu: "Применить фильтры",
    nameKz: "Сүзгілерді қолдану",
  },
  'Категории': {
    nameRu: "Категории",
    nameKz: "Санаттар",
  },
  'Выбрано ': {
    nameRu: "Выбрано ",
    nameKz: "Таңдалған ",
  },
  'Контакты': {
    nameRu: "Контакты",
    nameKz: "Байланыс",
  },
  'Телефон': {
    nameRu: "Телефон",
    nameKz: "Телефон",
  }
  ,'Почта': {
    nameRu: "Почта",
    nameKz: "Пошта",
  },
  'Контактный номер телефона': {
    nameRu: "Контактный номер телефона",
    nameKz: "Байланыс телефон нөмірі",
  },
  'Электронный адрес': {
    nameRu: "Электронный адрес",
    nameKz: "Электрондық мекен-жайы",
  },
  'Депутаты': {
    nameRu: "Депутаты маслихата",
    nameKz: "Алматы қ. мәслихатының",
  },
  'галматы': {
    nameRu: "г. Алматы",
    nameKz: " депутаттары",
  },
  'Депутаты 1': {
    nameRu: "Депутаты ",
    nameKz: "Алматы қ.",
  },
  'Депутаты 2': {
    nameRu: "маслихата",
    nameKz: "мәслихатының ",
  }
  ,
  'Депутаты 3': {
    nameRu: "г. Алматы",
    nameKz: "депутаттары",
  },
  'Маслихат города Алматы': {
    nameRu: "Маслихат города Алматы",
    nameKz: "Алматы қаласының мәслихаты",
  },
  'Избирательный округ': {
    nameRu: "Избирательный округ",
    nameKz: "сайлау округі",
  },
  'депутат': {
    nameRu: "депутат маслихата по избирательному округу №",
    nameKz: "сайлау округі бойынша мәслихат депутаты",
  },
  'Прием граждан': {
    nameRu: "Прием граждан",
    nameKz: "Азаматтарды қабылдау",
  },
  'Оставить обращение': {
    nameRu: "Оставить обращение",
    nameKz: "Өтінішті қалдыру",
  },
  'Место приема': {
    nameRu: "Место приема",
    nameKz: "Қабылдау орны",
  }
  ,
  'Контактное лицо': {
    nameRu: "Контактное лицо",
    nameKz: "Байланыс тұлғасы",
  },
  'Дни и часы приема': {
    nameRu: "Дни и часы приема",
    nameKz: "Қабылдау күндері мен сағаттары",
  }
  ,
  'Список округов': {
    nameRu: "Список округов",
    nameKz: "Округтер тізімі",
  },
  'рус.яз': {
    nameRu: "Орыс тіліне ауысу",
    nameKz: "Переключить на русский язык",
  },
  'каз.яз': {
    nameRu: "Қазақ тіліне ауысу",
    nameKz: "Переключить на казахский язык",
  },
  'Выберите избирательный округ': {
    nameRu: "Выберите избирательный округ",
    nameKz: "Сайлау округін таңдаңыз",
  },
  'Введите адрес': {
    nameRu: "Введите адрес",
    nameKz: "Мекенжайды енгізіңіз",
  },
  'Поиск': {
    nameRu: "Поиск",
    nameKz: "Іздеу",
  },
};
