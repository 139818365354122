import React, {useEffect, useState} from "react";
import "../../utils/reset.css";
import Map from "./Layer/Map";
import "./Style/Main.css";
import {lanGs} from "../../utils/langData";
import {Drawer, Popconfirm, Select} from "antd";
import {ReactComponent as Mail} from '../../assets/icon/mail.svg'
import {ReactComponent as Mans} from '../../assets/icon/mans.svg'
import {ReactComponent as Tel} from '../../assets/icon/tel.svg'
import {ReactComponent as Clock} from '../../assets/icon/clock.svg'
import {ReactComponent as Mark} from '../../assets/icon/mark.svg'
import DefImg from '../../assets/defPhoto.png'

const Main = ({lang, data, toMark, setToMark, isLoad, setIsLoad}) => {

    const [map, setMap] = useState(null);
    const [cardSelect, setCardSelect] = useState(null);
    const [cardHow, setCardHow] = useState(null);

    const [open, setOpen] = useState(false)
    const [mobile, setMobile] = useState(false)
    const compare = (a, b) => {
        return a.regionNumber - b.regionNumber;
    };
    const handleChange = (value, item) => {
        !mobile && setOpen(true);
        if (item.card) {
            setCardSelect(null);
            setToMark(null);
            setCardSelect(item.card)
            setCardHow(value)
            setToMark([item.card?.marker?.coordinates[1], item.card?.marker?.coordinates[0]])
        }
    };
    const onClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        setMobile(!isMobileDevice())
    }, []);

    function isMobileDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
    }


    return (
        <div className={"Main_wrapper"}>
            <div className={'List_state_wrapper'}>

                <div className={'List_state_wrap'}>
                    <h2>{lanGs['Список округов'][lang]}</h2>
                    {data && data.sort(compare).map(i => {
                        return <div className={`List_state ${cardHow === i.regionNumber ? 'hover' : ''}`} key={i.regionNumber + 'reg'} onClick={() => {
                            setCardSelect(null);
                            setToMark(null);
                            setCardSelect(i)
                            setCardHow(i.regionNumber)
                            setToMark([i?.marker?.coordinates[1], i?.marker?.coordinates[0]])
                        }}>

                            <span>{i?.county[lang]} </span>
                        </div>
                    })}
                </div>
                {cardSelect &&
                    <div className={'Maslihat_modal_wrap'}>
                        <img className={'Maslihat_modal_img'} src={cardSelect?.img? cardSelect?.img: DefImg} alt=""/>
                        <span className={'Maslihat_modal_fio'}>{cardSelect?.supervisor ? cardSelect?.supervisor[lang] : ''}</span>
                        <div className={'Maslihat_modal_post'}>
                            {lang === 'nameRu' ? lanGs['депутат'][lang] + cardSelect.regionNumber : ' №' + cardSelect.regionNumber + ' ' + lanGs['депутат'][lang]}
                        </div>
                        <a className={'mail_btn'} href={`mailto:${cardSelect.email}`}>{cardSelect.email}</a>
                        {/*<a className={'tel_btn'} href={`tel:+${cardSelect.phone}`}>{cardSelect.phone}</a>*/}
                        <a className={'blank_btn first_btn'} href="https://eotinish.kz/sendAppeal?orgId=60400&utm_medium=cpc&utm_campaign=gov_kz&utm_content=gov_kz"
                           target={'_blank'}>{lanGs[`Оставить обращение`][lang]}</a>
                        <Popconfirm
                            disabled={!cardSelect?.reception}

                            showCancel={false}
                            overlayClassName={'Tooltip_maslihat_card'}
                            description={cardSelect?.reception && <div className={'childPop_style'}>
                                <div className={'childPop_style_item'}>
                                    <Clock/><span className={'name_childPop'}>
                                {lanGs['Дни и часы приема'][lang]}:</span>
                                    <span>{cardSelect?.reception.admissionHours ? cardSelect?.reception.admissionHours[lang] : ''}</span>
                                </div>
                                <div className={'childPop_style_item'}>
                                    <Mark/>
                                    <span className={'name_childPop'}>{lanGs['Место приема'][lang]}: </span>
                                    <span>{cardSelect?.reception.address ? cardSelect?.reception.address[lang] : ''}</span>
                                </div>
                                <div className={'childPop_style_item'}>
                                    <Mans/>
                                    <span className={'name_childPop'}>{lanGs['Контактное лицо'][lang]}: </span>
                                    <span>{cardSelect?.reception.supervisor ? cardSelect?.reception.supervisor[lang] : ''}</span>
                                </div>
                                <div className={'childPop_style_item'}>
                                    <Tel/>
                                    <span className={'name_childPop'}>{lanGs['Телефон'][lang]}: </span>
                                    <span>{cardSelect?.reception.phone ? cardSelect?.reception.phone : ''}</span>
                                </div>
                                <div className={'childPop_style_item'}>
                                    <Mail/>
                                    <span className={'name_childPop'}>Email: </span>
                                    <span>{cardSelect?.reception.email ? cardSelect?.reception.email : null}</span>
                                </div>
                            </div>}
                        >
                        <span
                            className={'blank_btn second_btn'}
                            style={{backgroundColor: `${cardSelect.reception ? '' : 'gray'}`, cursor: `${cardSelect.reception ? '' : 'inherit'}`}}>
                            {lanGs[`Прием граждан`][lang]}
                        </span>
                        </Popconfirm>

                        <span className={'cross_btn_card'} onClick={() => {

                            setCardSelect(null)
                            setToMark(null)
                        }}>&#10006;</span>

                    </div>
                }
            </div>
            <div className={'List_state_wrapper mob_v'}>
                <Select

                    value={cardSelect ? lang === 'nameRu' ?
                        lanGs['Избирательный округ'][lang] + ' №' + cardSelect.regionNumber :
                        ' №' + cardSelect.regionNumber + ' ' + lanGs['Избирательный округ'][lang] :
                        lanGs["Выберите избирательный округ"][lang]}
                    onChange={handleChange}
                    style={{
                        width: '250px',
                    }}
                    listItemHeight = { 10 } listHeight = { 200 }
                    labelInValue={false}

                    bordered={false}
                    options={data?.sort(compare).map(i => {
                        return {
                            label: lang === 'nameRu' ? lanGs['Избирательный округ'][lang] + ' №' + i.regionNumber : ' №' + i.regionNumber + ' ' + lanGs['Избирательный округ'][lang],
                            value: i.regionNumber,
                            card: i,

                        }
                    })}
                />
            </div>
            <Map
                map={map}
                setMap={setMap}
                data={data}
                setCardSelect={setCardSelect}
                toMark={toMark}
                setToMark={setToMark}
                lang={lang}
                cardSelect={cardSelect}
                setCardHow={setCardHow}
                cardHow={cardHow}
                isLoad={isLoad}
                setIsLoad={setIsLoad}
                setOpen={setOpen}
                mobile={mobile}
            /> {/* тело карты*/}

            {cardSelect && !mobile && <Drawer
                title={cardSelect?.county[lang]}
                placement="bottom"
                closable={false}
                onClose={onClose}
                open={open}
                getContainer={false}
                height={'auto'}
                className={'Drawer_wrapper'}
            >
                <div className={'mob_bottom_card_wrap'}>

                    <img className={'Maslihat_modal_img'} src={cardSelect?.img ? cardSelect?.img : DefImg} alt=""/>
                    <div className={'Drawer_info'}>
                        <span className={'Maslihat_modal_fio'}>{cardSelect?.supervisor ? cardSelect?.supervisor[lang] : ''}</span>
                        <div className={'Maslihat_modal_post'}>
                            {lang === 'nameRu' ? lanGs['депутат'][lang] + cardSelect.regionNumber : ' №' + cardSelect.regionNumber + ' ' + lanGs['депутат'][lang]}
                        </div>
                        <a className={'mail_btn'} href={`mailto:${cardSelect.email}`}>{cardSelect.email}</a>
                        {/*<a className={'tel_btn'} href={`tel:+${cardSelect.phone}`}>{cardSelect.phone}</a>*/}

                        <Popconfirm
                            disabled={!cardSelect?.reception}

                            showCancel={false}
                            overlayClassName={'Tooltip_maslihat_card'}
                            description={cardSelect?.reception && <div className={'childPop_style'}>
                                <div className={'childPop_style_item'}>
                                    <Clock/><span className={'name_childPop'}>
                                {lanGs['Дни и часы приема'][lang]}:</span>
                                    <span>{cardSelect?.reception.admissionHours ? cardSelect?.reception.admissionHours[lang] : ''}</span>
                                </div>
                                <div className={'childPop_style_item'}>
                                    <Mark/>
                                    <span className={'name_childPop'}>{lanGs['Место приема'][lang]}: </span>
                                    <span>{cardSelect?.reception.address ? cardSelect?.reception.address[lang] : ''}</span>
                                </div>
                                <div className={'childPop_style_item'}>
                                    <Mans/>
                                    <span className={'name_childPop'}>{lanGs['Контактное лицо'][lang]}: </span>
                                    <span>{cardSelect?.reception.supervisor ? cardSelect?.reception.supervisor[lang] : ''}</span>
                                </div>
                                <div className={'childPop_style_item'}>
                                    <Tel/>
                                    <span className={'name_childPop'}>{lanGs['Телефон'][lang]}: </span>
                                    <span>{cardSelect?.reception.phone ? cardSelect?.reception.phone : ''}</span>
                                </div>
                                <div className={'childPop_style_item'}>
                                    <Mail/>
                                    <span className={'name_childPop'}>Email: </span>
                                    <span>{cardSelect?.reception.email ? cardSelect?.reception.email : null}</span>
                                </div>
                            </div>}
                        >
                        <span
                            className={'blank_btn second_btn'}
                            style={{backgroundColor: `${cardSelect.reception ? '' : 'gray'}`, cursor: `${cardSelect.reception ? '' : 'inherit'}`}}>
                            {lanGs[`Прием граждан`][lang]}
                        </span>
                        </Popconfirm>
                        <a className={'blank_btn first_btn'}
                           href="https://eotinish.kz/sendAppeal?orgId=60400&utm_medium=cpc&utm_campaign=gov_kz&utm_content=gov_kz"
                           target={'_blank'}>{lanGs[`Оставить обращение`][lang]}</a>

                    </div>


                </div>

            </Drawer>}


        </div>
    );
};

export default Main;
