import React from 'react';
import '../Style/PopupItem.css'
import {ReactComponent as Mark} from '../Image/mark.svg'
import {ReactComponent as Like} from '../Image/like.svg'
import {lanGs} from "../../../utils/langData";
const PopupItem = ({data, lang}) => {



    return (
        <div className={'PopupItem_wrap'}>
            {/*название*/}
            {data && <div className={`Popup_items name `} key={data.id + 'id' + Math.random(500)} title={'Округ'}>
                <span> {lang === 'nameRu'? lanGs['Избирательный округ'][lang]  +' №' + data.regionNumber : ' №' + data.regionNumber + ' ' + lanGs['Избирательный округ'][lang]}</span>
            </div>}
            {/*Категория*/}

            {/*район*/}
            {data.district  && <div className={`Popup_items`} key={'district ' + Math.random(300)} title={ lanGs['Район'][lang]}>
                    <div className={'popup_line_style'}>
                         <span><Mark/></span>  <span>{data?.district[lang] ? data.district[lang]: '' }</span>
                    </div>
                </div>}
            {/*Адрес*/}
            {data.address && <div className={`Popup_items`} key={'address ' + Math.random(100)} title={lanGs['Адрес'][lang]}>
                    <span>
                        {lanGs['Адрес'][lang]}: {data.address? data.address[lang]:''}
                    </span>
                </div>}

            <div className={'Popup_items_double'}>
               {/* статус*/}
                {data.status && <div className={`Popup_items`} key={'status ' + Math.random(10)} title={lanGs['Статус'][lang]}>
                    <span style={{color: `${data.status?.color ? data.status.color: '#000000'}`}}>⬤</span>
                    <span>{data.status[lang]}</span>
                </div>}

               {/* лайки*/}
                {/*{data.neg?.toString() && data.pos?.toString() && <div title={'Лайки'} className={'Popup_items_likes'}>
                    <div className={`Popup_items like_pos`} key={'like ' + 'pos'} >
                       <Like/>
                        <span>{data.pos}</span>
                    </div>
                    <div className={`Popup_items likes like_neg`} key={data.neg + id + 'neg'} >
                        <Like />
                        <span>{data.neg}</span>
                    </div>

                </div>}*/}
            </div>

        </div>
    );
};

export default PopupItem;


