import React, { useState } from "react";
import "./HeaderBlock.css";
import { ReactComponent as Logo1 } from "../assets/logo/CRA.svg";
import { ReactComponent as Logo2 } from "../assets/logo/gerb.svg";
import { ReactComponent as Logo3 } from "../assets/logo/apple.svg";

import LogoCRA from "../assets/logo/LogoCRA.png";
import LogoMasl from "../assets/logo/maslihat.png";
import { ReactComponent as Form } from "../assets/logo/form.svg";
import { lanGs } from "../utils/langData";

const HeaderBlock = ({ setLang, lang }) => {
  return (
    <div className={`HeaderBlock_wrapper`}>
      <div className={`HeaderBlock_wrap`}>
        <span className={"Title_block_name"}>

          <span>{lanGs["Депутаты"][lang]}</span>
          <span>{lanGs["галматы"][lang]}</span>
        </span>
        <a
            href={`https://www.gov.kz/memleket/entities/maslihat-almaty?lang=${lang === 'nameRu'? 'ru': 'kk'}`}
            target={"_blank"}
            className={`HeaderBlock_item st1`}
            title={lanGs["Маслихат города Алматы"][lang]}
        >
          <img src={LogoMasl} alt="лого ситуационного центра города алматы"  className={'logo_masl'}/>
          <span className={"Header_logo_text"}>
            {lanGs["Маслихат города Алматы"][lang].slice(0, 16)} <br />{" "}
            {lanGs["Маслихат города Алматы"][lang].slice(16)}
          </span>
        </a>
        <a
          href={"https://opendata.smartalmaty.kz/"}
          target={"_blank"}
          className={`HeaderBlock_item home_icon st2`}
          title={"Ситуационный центр города Алматы"}
        >
          <img src={LogoCRA} alt="лого ситуационного центра города алматы" />
          <div className={"home_icon_text"}>
            <span>{lanGs["Ситуационный центр"][lang]}</span>

          </div>
        </a>

        <a
          href={`${lang === 'nameKz'? 'https://almatydc.kz/kk/': 'https://almatydc.kz/'}`}
          target={"_blank"}
          className={`HeaderBlock_item st3`}
          title={lanGs["Центр развития города Алматы"][lang]}
        >
          <Logo1 />
          <span className={`cifra_logo_text`}>
            {lanGs["Центр развития города Алматы"][lang].slice(0, 14)}
            <br />
            {lanGs["Центр развития города Алматы"][lang].slice(14)}
          </span>
        </a>
        <a
          href={`https://www.gov.kz/memleket/entities/almaty?lang=${lang === 'nameRu'? 'ru': 'kk'}`}
          target={"_blank"}
          className={`HeaderBlock_item st4`}
          title={lanGs["Акимат города Алматы"][lang]}
        >
          <Logo2 />
          <span className={"Header_logo_text"}>
            {lanGs["Акимат города Алматы"][lang].slice(0, 6)} <br />{" "}
            {lanGs["Акимат города Алматы"][lang].slice(6)}
          </span>
        </a>
        <a
          href={`https://digital-almaty.kz/${lang === 'nameRu'? 'ru': 'kz'}`}
          target={"_blank"}
          className={`HeaderBlock_item st5`}
          title={lanGs["Управление Цифровизации города Алматы"][lang]}
        >
          <Logo3 />
          <div className={`cifra_logo_text`}>
            <span>
              {lanGs["Управление Цифровизации города Алматы"][lang].slice(
                0,
                13
              )}
            </span>
            <span>
              {lanGs["Управление Цифровизации города Алматы"][lang].slice(
                13,
                25
              )}
            </span>
            <span>
              {lanGs["Управление Цифровизации города Алматы"][lang].slice(25)}
            </span>
          </div>
        </a>

        <div className={"lang_block"} >
          <span
            onClick={() => setLang("nameRu")}
            className={`lang_btn_item ${lang === "nameRu" ? "active" : ""}`}
            title={lanGs["рус.яз"][lang]}
          >
            РУС
          </span>
          <span>/</span>
          <span
            onClick={() => setLang("nameKz")}
            className={`lang_btn_item ${lang === "nameKz" ? "active" : ""}`}
            title={lanGs["каз.яз"][lang]}
          >
            ҚАЗ
          </span>
        </div>
        <div className={"Contact_block"} title={lanGs["Оставить отзыв"][lang]}>
          <a
            href="https://forms.gle/uAdQWfjJuySpGQjh9"
            target={"_blank"}
            className={"Contact_block_item"}
          >
            <Form />
            <span className={'back_mail'}>{lanGs["Оставить отзыв"][lang]}</span>
          </a>
        </div>
       {/* <a
          href="https://demo.opendata.smartalmaty.kz/monitoring-akim/"
          target="_self"
          className="HeaderBlock_item akim_panel "
          title={`${lanGs["Панель руководителя"][lang]}`}
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m12 11c2.2091 0 4-1.79086 4-4s-1.7909-4-4-4c-2.20914 0-4 1.79086-4 4s1.79086 4 4 4zm0 2c-3.27164 0-6.17632 1.5711-8.00073 4 1.82441 2.4289 4.72909 4 8.00073 4 3.2716 0 6.1763-1.5711 8.0007-4-1.8244-2.4289-4.7291-4-8.0007-4z"
              fill="#ffffff"
            ></path>
          </svg>
          {lanGs["Вход"][lang]}
        </a>*/}
        {/*<a href={'https://sit-admin.smartalmaty.kz/oauth/logout'} target={'_self'} className={`HeaderBlock_item`}
         title={'Ситуационный центр города Алматы'}>
         <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
         <path
         d="m12 11c2.2091 0 4-1.79086 4-4s-1.7909-4-4-4c-2.20914 0-4 1.79086-4 4s1.79086 4 4 4zm0 2c-3.27164 0-6.17632 1.5711-8.00073 4
         1.82441 2.4289 4.72909 4 8.00073 4 3.2716 0 6.1763-1.5711 8.0007-4-1.8244-2.4289-4.7291-4-8.0007-4z"
         fill="#ffffff"/>
         </svg>
         Вход
         </a>*/}
      </div>
    </div>
  );
};

export default HeaderBlock;
