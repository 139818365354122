import React from "react";

export const uniqueItems = (datas, items, endItem) => {

    return datas.reduce((acc, st) => {
        if (acc.arr[st[items][endItem]]) // если данный объект уже был
            return acc; // ничего не делаем, возвращаем уже собранное
        acc.arr[st[items][endItem]] = true; // помечаем объект, как обработанный
        acc.result.push(st.status); // добавляем объект в массив объектов
        return acc; // возвращаем собранное
    }, {
        arr: {}, // здесь будут отмечаться обработанные объекты
        result: [] // здесь конечный массив объектов
    }).result; // получаем конечный массив объектов
}

export const  reverseCoordinates = (array) =>  {
    return Array.isArray(array) ? array.map(reverseCoordinates) : array.map(coord => Array.isArray(coord) ? reverseCoordinates(coord) : [coord[1], coord[0]]);
}
export const ModifiedArray = (cords) =>  cords.map(item => {
   return  item.map(subItem => {
        return subItem.map(coord => {
            return [coord[1], coord[0]]; // Меняем местами координаты
        });
    });

});


