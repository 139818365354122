import './App.css';
import CityInfrastructure from "./components/CityInfrastructure";
import './common/Map/Style/updateCss.css'
import './media.css'
function App() {
    return (
        <div className="App">
           <CityInfrastructure/>
        </div>
    );
}

export default App;

